body {
  background-color: #f5f7fb!important;
}

.loading.cover-content {
  position: absolute!important;
  left: 50%!important;
  top: 50%!important;
  -webkit-transform: translate(-50%, -50%)!important;
  transform: translate(-50%, -50%)!important;
}

.loading.cover-page {
  position: fixed!important;
  width: 100%!important;
  height: 100%!important;
  display: -webkit-flex!important;
  display: flex!important;
  -webkit-align-items: center!important;
  align-items: center!important;
  -webkit-justify-content: center!important;
  justify-content: center!important;
}

.loading .ant-spin {
  color: #3e79f7!important;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected,
.ant-menu-item:hover,
.ant-menu-submenu:hover,
.ant-menu-submenu-title:hover {
  position: relative!important;
  color: rgb(228, 228, 228) !important;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop.ant-menu-submenu-hidden {
  max-width: 250px !important;
}

.customSideNav .ant-menu-item-selected::after {
  content: ""!important;
  position: absolute!important;
  width: 40px!important;
  height: 40px!important;
  right: 0!important;
  background: transparent!important;
  top: -40px !important;
  border-right: transparent !important;
  border-radius: 0 0 100vw 0!important;
  box-shadow: 10px 10px 0 10px #2c6296!important;
  transition: all 0.2s !important;
}

.ant-menu-item {
  transition: all 0.1s !important;
}

.customSideNav .ant-menu-item.ant-menu-item-selected::before {
  content: ""!important;
  position: absolute!important;
  width: 40px!important;
  height: 40px!important;
  right: 0!important;
  background: transparent!important;
  top: 40px!important;
  border-radius: 0 100vw 0 0!important;
  box-shadow: 10px -10px 0 10px #3c6296!important;
  z-index: 1!important;
  transition: all 0.2s !important;
}

.ant-layout-sider-collapsed .ant-menu-item.ant-menu-item-selected::before,
.ant-layout-sider-collapsed .ant-menu-item.ant-menu-item-selected::after {
  display: none!important;
}

.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 0px !important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}

.ant-layout-sider-collapsed .ant-menu-item {
  margin-left: 22px!important;
  margin-right: 22px!important;
}

.ant-layout-sider-collapsed .ant-menu-item.ant-menu-item-selected {
  background-image: linear-gradient(187deg, #5264F9 -68%, #3FAAC6 90%) !important;
  border-radius: 5px!important;
  box-shadow: 0px 2px 3px 1px #fcfcfc3b!important;
}

/* Global or component-level styles */
.custom-otp-input {
  /* Add any specific styling for the OTP input */
  border: 1px solid #ccc !important;
  /* Use !important to ensure it overrides other styles */
  border-radius: 4px!important;
  padding: 0.5rem!important;
  outline: none!important;
  width: 3.2rem !important;
  height: 3rem !important;
}

.custom-otp-input:focus {
  border-color: #3CA6C1 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)!important;
}

.OtpInput {
  justify-content: space-between!important;
}

.slick-dots li button:before {
  content: '' !important;
}

.slick-dots li {
  width: auto !important;
}

.slick-dots li button {
  background: rgba(255, 255, 255, 0.293) !important;
  border-radius: 50%!important;
  transition: 0.3s!important;
}

.slick-dots li.slick-active button {
  background: rgb(238, 238, 238) !important;
  border-radius: 10px!important;
  width: 50px !important;
}

.loginRightSlider .slick-dots {
  position: absolute !important;
  bottom: 4rem!important;
  left: 4rem!important;
  display: flex !important;
  gap: 4px!important;
  width: 250px!important;
}

.loginRightSlider {
  padding: 2rem!important;
}

.loginRightSlider .slick-list {
  border-radius: 20px!important;
}

.app-header.ant-layout-header {
  height: 110px !important;
  background-color: #fff!important;
}

.app-content {
  margin-top: 100px !important;
  min-height: calc(100vh - 165px) !important;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  height: 100%!important;
  border-right: 0px!important;
  padding-left: 1.2rem!important;
}

.side-nav {
  height: calc(100vh - 110px) !important;
  position: fixed!important;
  top: 110px !important;
}

.app-header {
  box-shadow: none !important;
  background-image: url('./wavegrey.svg')!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  background-position: top!important;
  background-color: transparent!important;
}

.ant-menu-item {
  border-top-left-radius: 22px!important;
  border-bottom-left-radius: 22px!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(44 98 149)!important;
  overflow: visible!important;
  /* border-radius: 22px!important; */
}

/* ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light{
  padding: !important;
} */
.logo {
  height: 110px!important;
  background-image: linear-gradient(187deg, #4683e5 50%, #4684e5)!important;
}

aside ul.customSideNav {
  background: transparent !important;
  /* background-image: linear-gradient(187deg, #5264F9 -68%, #3FAAC6 90%) !important; */
}

.ant-layout-sider-children>div {
  background-image: linear-gradient(187deg, #5264F9 -68%, #3FAAC6 90%) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover {
  border: none !important;
}

.sideNavUserImage {
  width: 60px!important;
  height: 60px!important;
  margin: auto!important;
  display: block!important;
  object-fit: cover!important;
  border-radius: 50%!important;
  margin-top: 2rem!important;
}

.profileCard {
  padding-right: 1.2rem!important;
  height: 110px!important;
}

.ant-menu-inline-collapsed .profileCard {
  padding-right: 0!important;
  height: 100px!important;
}

.ant-menu-inline-collapsed .profileCard h4,
.ant-menu-inline-collapsed .profileCard p {
  display: none!important;
}


/* extra css posible to remove in :future */
.ant-menu-inline-collapsed .ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item {
  padding: 0px calc(50% - 30px) !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-title {
  margin-left: 22px!important;
  margin-right: 22px!important;
  padding: 0px calc(50% - 30px) !important;
}

.ant-menu-item:hover svg {
  color: white !important;
}

/* .ant-menu-title-content{
  display: flex!important;
  align-items: center!important;
} */
.ant-table-thead>tr>th {
  color: #5badd5!important;
}

.bg-primary {
  background-color: #5badd5 !important;
}
.text-primary {
  color: #5badd5 !important;
}

.customimgUploader .avatar-uploader .ant-upload {
  width: 100%!important;
  height: 200px!important;
  /* margin-top: 1rem!important; */
}

.avatar-uploader .ant-upload img {
  width: 100%!important;
  height: 90%!important;
  margin: auto!important;
  object-fit: contain!important;
}

.greyHeaderTable th {
  background-color: #f7f7f7 !important;
  color: black !important;
}
.uploaddoc {
  height: 230px!important;
  border: 2px dashed rgb(161, 198, 239)!important;
  border-radius: 5px!important;
}
.transparent th {
  background-color: #ffffff00 !important;
  color: black !important;
}

.customForm .ant-form-item-required::before {
  display: none !important;
}

.ant-modal-header {
  border-bottom: none!important;
}

.bg-grey {
  background: #F8F8F8!important;
}

.customTableBackground .ant-tabs-nav {
  background-color: white !important;
  /* padding-top: 1.5rem!important; */
}

.customTableBackground .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3CA6C1 !important;
}

.customTableBackground .ant-tabs-ink-bar {
  background: #3CA6C1 !important;
}

.displayCenter {
  display: flex!important;
  justify-content: space-between!important;
  align-items: center!important;
}
.displayCenter p{
  margin: 0!important;
}
.displayCenter span{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border-radius: 50%!important;
  height: 22px!important;
  width: 22px!important;
  background-color: red!important;
  color: white!important;
  font-weight: 500!important;
  font-size: 12px!important;
}

.totalAdminCircle{
  background: linear-gradient(to bottom, #7A90F6 100%, #5F78EE 100%)!important;
}
.totalUserCircle{
  background: linear-gradient(to bottom,#FF6188 100%, #FF9E81 100%)!important;
}
.totalManagerCircle{
  background: linear-gradient(to bottom, #4FB0FE 100%, #2B9BF6 100%)!important;
}
.border-dashed{
  border-left: 3px dashed #cecece!important;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup.-undefined.ant-dropdown-menu-submenu-placement-rightTop ul{
  display: block !important;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup.-undefined.ant-dropdown-menu-submenu-placement-rightTop.ant-dropdown-menu-submenu-hidden ul{
  display: none !important;
}
/* .ant-dropdown-menu-submenu-popup ul{
  display: none!important;
} */
/* .ant-dropdown-open .ant-dropdown-menu-sub.ant-dropdown-menu-vertical{
  display: block !important;
} */
.font-weight-300{
  font-weight: 300!important;
}
.customDashedBorder{
  border: 2px dashed #cccccc!important;
    height: 185px!important;
    border-radius: 20px!important;
    margin: 0.5rem!important;
    padding: 1rem!important;
}
.customDashedBorder img{
  object-fit: contain!important;
    width: 100%!important;
    height: 100%!important;
}
.ant-steps-item-icon{
  top: -24px !important;
  height: 40px !important;
  left: -16px!important;
}
.ant-steps-item-icon{
  position: relative!important;
  top: -24px !important;
}
.ant-steps-item.ant-steps-item-process {
  width: 110px !important;
}
.report-modal-boxes{
  align-items: center!important;
  padding: 8px 12px!important;
  border: 1px solid #e9e9e9!important;
  border-radius: 8px!important;
  margin: 22px 0px!important;
}
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
  right: 16px !important;
  display: flex!important;
  justify-content: flex-end!important;
}
.custom-full-width .ant-space-vertical{
  width: 100%!important;
}
.custom-full-width .ant-collapse-header{
  padding: 12px 16px 12px 16px !important;
}
.ant-collapse-item.ant-collapse-item-active .ant-collapse-header{
  background: #4fb0caa8!important;
    color: white!important;
}
.ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  border-radius: 0.625rem 0.625rem 0px 0px !important;
}
.normal-header-color .ant-collapse-item .ant-collapse-header{
  background: #fdfdfdff!important;
    color: rgb(0, 0, 0)!important;
}
.normal-header-color .ant-collapse-item .ant-collapse-header {
  border-radius: 0.625rem !important;
}
.normal-header-color .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{
  background: #f4f4f4!important;
    color: rgb(0, 0, 0)!important;
}
.normal-header-color .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  border-radius: 0.625rem 0.625rem 0px 0px !important;
}
.customEditButton{
  display: none!important;
  position: absolute!important;
    right: 2.5rem!important;
    top: 6px!important;
    height: 35px!important;
    padding: 0 20px!important;
}
.normal-header-color .ant-collapse-item.ant-collapse-item-active .customEditButton{
  display: block!important;
}

.grey-table .ant-table {
  box-sizing: border-box!important;
  margin: 0!important;
  padding: 0!important;
  color: rgba(0, 0, 0, 0.85)!important;
  font-variant: tabular-nums!important;
  line-height: 1.5715!important;
  list-style: none!important;
  font-feature-settings: 'tnum'!important;
  position: relative!important;
  font-size: 14px!important;
  background: #fff!important;
}
.grey-table .ant-table table {
  width: 100%!important;
  text-align: left!important;
  border-radius: 2px 2px 0 0!important;
  border-collapse: separate!important;
  border-spacing: 0!important;
}
.grey-table .ant-table-thead > tr > th {
  position: relative!important;
  color: rgba(0, 0, 0, 0.85)!important;
  font-weight: 500!important;
  text-align: left!important;
  background: #fafafa!important;
  border-bottom: 1px solid #f0f0f0!important;
  transition: background 0.3s ease!important;
}

.green-radio .ant-radio-inner::after{
  background-color: #01A643 !important;
}
.green-radio .ant-radio-input:focus + .ant-radio-inner{
  border-color: #01A643 !important;
}
.green-radio .ant-radio-checked .ant-radio-inner{
  border-color: #01A643 !important;
}
.dashed-border{
  min-height: 300px!important;
    border: 2.5px dashed #80808030!important;
    border-radius: 8px!important;
}
.red-radio .ant-radio-inner::after{
  background-color: #FF4646 !important;
}
.red-radio .ant-radio-input:focus + .ant-radio-inner{
  border-color: #FF4646 !important;
}
.red-radio .ant-radio-checked .ant-radio-inner{
  border-color: #FF4646 !important;
}
.grey-radio .ant-radio-inner::after{
  background-color: #d2d2d2 !important;
}
.grey-radio .ant-radio-input:focus + .ant-radio-inner{
  border-color: #d2d2d2 !important;
}
.grey-radio .ant-radio-checked .ant-radio-inner{
  border-color: #d2d2d2 !important;
}
.custom-event-bg {
  background-color: #FDF1D8 !important; 
  border: none; 
  color: #0000 !important;
}
